/* global EF */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import logo from "./images/Agent_logo.png";
import logob from "./images/Agent_logo_black.svg";
import Popup from "reactjs-popup";
import BurgerIcon from "./BurgerIcon";
import fire from './config/Fire';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { getTranslation } from './utils';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      user: null,
    
    });
    this.authListener = this.authListener.bind(this);
    this.logout = this.logout.bind(this);
    EF.globals.marketCode='';
  }

  onChange = (e) => {
    //this.props.history.push(`/${e.target.value}`);
    window.location.href='/?lang='+e.target.value;
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }


  componentDidMount() {
    this.authListener();
    function getQueryVariable(variable){
      var query = window.location.search.substring(1);
      //console.log(query);
      var vars = query.split("&");
      //console.log(vars); 
      for (var i=0;i<vars.length;i++) {
       
                  var pair = vars[i].split("=");
                  console.log(pair[1]); 
                  EF.globals.marketCode=pair[1];
                  console.log(EF.globals.marketCode);          
      if(pair[0] === variable){return pair[1];}
       }
      
         
}
getQueryVariable('lang');
  }




  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.setState({ user });
        localStorage.setItem('user', user.uid);
      } else {
        this.setState({ user: null });
        localStorage.removeItem('user');
      }
    });
  }
  logout() {
    fire.auth().signOut();
    window.location.href = "/";
  }


  render() {
    
   
   
   
    const { location } = this.props
    return [
      <header className={location.pathname === '/' ? 'transparent' :'white' }>
        <div className="row-head">
          <div className="head-logo">
            <NavLink to="/">
              <img src={location.pathname === '/' ? logo :logob } alt="EF"/>
              
            </NavLink>
          </div>
          <div className="head-link">
            <ul>
            <li class="langNav">
            <select onChange={this.onChange} className="langDrop" value={EF.globals.marketCode}>
               <option value="we">English</option>
               <option value="wc">Français</option>
               <option value="wl">Español</option>
               <option value="sa">العربية</option>
               <option value="wr">Русский</option>
               <option value="kr">한국어</option>
               <option value="cn">中文</option>
            </select>
             
              </li>
              
              {this.state.user && <li>
                <button onClick={this.logout} className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu9`)}
                </button>
              </li>}
              {!this.state.user && <li>
                <NavLink to="/SignIn/" activeClassName="active" className="button contact-btn btn-border desk-menu-show">
                  {getTranslation(`header.menu.menu1`)}
                </NavLink>
              </li>}
              {!this.state.user && <li>
                <NavLink to="/Register/" activeClassName="active" className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu2`)}
                </NavLink>
              </li>}
             
              <li>
                <NavLink to="/ContactUs/" activeClassName="active" className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu3`)}
                </NavLink>
              </li>
              
              
              {this.state.user && <li>
                <NavLink to="/Promos/" activeClassName="active" className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu8`)}
                </NavLink>
              </li>}
              {this.state.user && <li>
                <NavLink to="/PriceQuotations/" activeClassName="active" className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu10`)}
                </NavLink>
              </li>}
              {this.state.user && <li>
                <NavLink to="/Courses/" activeClassName="active" className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu7`)}
                </NavLink>
              </li>}
              {this.state.user && <li>
                <NavLink to="/Materials/" activeClassName="active" className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu6`)}
                </NavLink>
              </li>}

              {this.state.user && <li>
                <NavLink to="/Destinations/" activeClassName="active" className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu5`)}
                </NavLink>
              </li>}
              {this.state.user && <li>
                <NavLink to="/Agent/" activeClassName="active" className="button contact-btn desk-menu-show">
                  {getTranslation(`header.menu.menu4`)}
                </NavLink>
              </li>}

              <li class="mob-menu-show">
                <div>

                  <Popup
                    modal
                    closeOnDocumentClick={false}
                    trigger={open => <BurgerIcon open={open} />}
                  >
                    {close => (
                     
                      
                   
                      <div className="menu">
                        <NavLink onClick={close} to="/" className="monMenuLogo">
                        <img src={logob} alt="EF"/>
                      </NavLink>
                        <ul>
                          {this.state.user && <li>
                            <NavLink onClick={close} to="/Agent/" activeClassName="active" className="button contact-btn">
                              {getTranslation(`header.menu.menu4`)}
                            </NavLink>
                          </li>}
                          {this.state.user && <li>
                            <NavLink onClick={close} to="/Destinations/" activeClassName="active" className="button contact-btn">
                              {getTranslation(`header.menu.menu5`)}
                            </NavLink>
                          </li>}

                          {this.state.user && <li>
                            <NavLink onClick={close} to="/Materials/" activeClassName="active" className="button contact-btn">
                              {getTranslation(`header.menu.menu6`)}
                            </NavLink>
                          </li>}
                          {this.state.user && <li>
                            <NavLink onClick={close} to="/Courses/" activeClassName="active" className="button contact-btn">
                              {getTranslation(`header.menu.menu7`)}
                            </NavLink>
                          </li>}
                          {this.state.user && <li>
                            <NavLink onClick={close} to="/PriceQuotations/" activeClassName="active" className="button contact-btn">
                              {getTranslation(`header.menu.menu10`)}
                            </NavLink>
                          </li>}
                          {this.state.user && <li>
                            <NavLink onClick={close} to="/Promos/" activeClassName="active" className="button contact-btn ">
                              {getTranslation(`header.menu.menu8`)}
                            </NavLink>
                          </li>}
                          {!this.state.user && <li>
                            <NavLink onClick={close} to="/SignIn/" activeClassName="active" className="button contact-btn">
                              {getTranslation(`header.menu.menu1`)}
                            </NavLink>
                          </li>}
                          {!this.state.user && <li>
                            <NavLink onClick={close} to="/Register/" activeClassName="active" className="button contact-btn">
                              {getTranslation(`header.menu.menu2`)}
                            </NavLink>
                          </li>}
                          <li>
                            <NavLink onClick={close} to="/ContactUs/" activeClassName="active" className="button contact-btn">
                              {getTranslation(`header.menu.menu3`)}
                            </NavLink>
                          </li>
                          {this.state.user && <li>
                            <button onClick={this.logout} className="button contact-btn">{getTranslation(`header.menu.menu9`)}</button>
                          </li>}






                        </ul>
                      </div>
                    )}
                  </Popup>
                </div>
              </li>


            </ul>

          </div>
        </div>
      </header>


    ];
  }
}

const AdaptiveHeader = withRouter(Header)

export default AdaptiveHeader
