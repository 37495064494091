import React, { Component } from 'react';
import { getTranslation } from './utils';


export default class OrderMaterial extends Component {
  render() {
    return [
      <section class="section-div omaterial-hero">
        <div class="omaterial-hero-inner">
          <div class="omaterial-hero-content">
            <h1>{getTranslation(`OrderMaterial.hero.title`)}</h1>
            <p>{getTranslation(`OrderMaterial.hero.subtitle`)}</p>
          </div>
        </div>
      </section>,
      <section class=" section-div section-omaterial">
        <div class="omaterial-area">
          <div class="omaterial-box">

            <iframe title="Order Material"
              allowTransparency="true"
              frameborder="0"
              scrolling="no"

              src="https://efeducation.wufoo.com/embed/zzp1x0f1qppy1a/">
              <a href="https://efeducation.wufoo.com/forms/zzp1x0f1qppy1a/">
                Fill out my Wufoo form!
                          </a>
            </iframe>


          </div>
        </div>
      </section>

    ];
  }
}


