import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from 'react-light-accordion';
import Slider from "react-slick";
import lca from "./images/lca.jpg";
import jca from "./images/jca.jpg";
import aya from "./images/aya.jpg";
import upa from "./images/upa.jpg";
import logo1 from "./images/accreditions/logo_1.jpg";
import logo2 from "./images/accreditions/logo_2.jpg";
import logo3 from "./images/accreditions/logo_3.jpg";
import logo4 from "./images/accreditions/logo_4.jpg";
import logo5 from "./images/accreditions/logo_5.jpg";
import logo6 from "./images/accreditions/logo_6.jpg";
import logo7 from "./images/accreditions/logo_7.jpg";
import logo8 from "./images/accreditions/logo_8.jpg";
import logo9 from "./images/accreditions/logo_9.jpg";
import logo10 from "./images/accreditions/logo_10.jpg";
import fire from './config/Fire';
import { getTranslation } from './utils';


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      user: null,
      images: [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10],
    });
    this.authListener = this.authListener.bind(this);

  }

  componentDidMount() {
    this.authListener();
  }




  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.setState({ user });
        localStorage.setItem('user', user.uid);
      } else {
        this.setState({ user: null });
        localStorage.removeItem('user');
      }
    });
  }


  render() {
    const { images } = this.state;
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return [
      <section className="section-div hero-section">
        <div className="hero-inner">
          <div className="hero-content">
            <h1>{getTranslation(`home.hero.title`)}</h1>
            <p>{getTranslation(`home.hero.subtitle`)}</p>
            {!this.state.user && <div className="hero-btn-area">
              <Link to="/SignIn/" className="button hero-btn loginBtn">{getTranslation(`home.hero.cta1Text`)}</Link>
              <div className="hero-regArea">
                <span>{getTranslation(`home.hero.reglabel`)}</span>
              <Link to="/Register/" className="regBtn">{getTranslation(`home.hero.cta2Text`)}</Link>
              </div>
            </div>}
          </div>
        </div>
      </section>,
      <section className="section-div offer-section">
        <div className="offer-area">
          <div className="offer-box">
            <h1>{getTranslation(`home.card.title`)}</h1>
            <div className="offer-list">
              <ul>
                <li className="border-bottom">
                  <div className="offer-num">
                    <h1>{getTranslation(`home.card.card1`)}</h1>
                  </div>
                  <div className="offer-text">
                    <h3>{getTranslation(`home.card.card1Des`)}</h3>
                  </div>

                </li>
                <li className="border-bottom">
                  <div className="offer-num">
                    <h1>{getTranslation(`home.card.card2`)}</h1>
                  </div>
                  <div className="offer-text">
                    <h3>{getTranslation(`home.card.card2Des`)}</h3>
                  </div>
                </li>
                <li className="border-bottom border-top">
                  <div className="offer-num">
                    <h1>{getTranslation(`home.card.card3`)}</h1>
                  </div>
                  <div className="offer-text">
                    <h3>{getTranslation(`home.card.card3Des`)}</h3>
                  </div>
                </li>
                <li className="border-top">
                  <div className="offer-num">
                    <h1>{getTranslation(`home.card.card4`)}</h1>
                  </div>
                  <div className="offer-text">
                    <h3>{getTranslation(`home.card.card4Des`)}</h3>
                  </div>
                </li>
                <li className="border-top">
                  <div className="offer-num">
                    <h1>{getTranslation(`home.card.card5`)}</h1>
                  </div>
                  <div className="offer-text">
                    <h3>{getTranslation(`home.card.card5Des`)}</h3>
                  </div>
                </li>
                <li className="border-top">
                  <div className="offer-num">
                    <h1>{getTranslation(`home.card.card6`)}</h1>
                  </div>
                  <div className="offer-text">
                    <h3>{getTranslation(`home.card.card6Des`)}</h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>,
      <section className="section-prog">
        <div className="prog-head">
          <div className="prog-area">
          <h1>{getTranslation(`home.program.title`)}</h1>
          </div>
       
        </div>
        
        <div className="prog-area">
          <div className="prog-box">
            
            <div className="prog-row">
              <div className="prog-half">
                <img src={lca} alt="EF" />
              </div>
              <div className="prog-half lca">
                <div className="flex-center">
                  <h1>{getTranslation(`home.program.program1.title`)}<br /><span>{getTranslation(`home.program.program1.title2`)}</span></h1>
                  <p>{getTranslation(`home.program.program1.para`)}</p>
                </div>
              </div>
            </div>

            <div className="prog-row mob-reverse">
              <div className="prog-half aya">
                <div className="flex-center">
                  <h1>{getTranslation(`home.program.program2.title`)}<br /><span>{getTranslation(`home.program.program2.title2`)}</span></h1>
                  <p>{getTranslation(`home.program.program2.para`)}</p>
                </div>
              </div>
              <div className="prog-half">
                <img src={aya} alt="EF" />
              </div>

            </div>

            <div className="prog-row">
              <div className="prog-half">
                <img src={upa} alt="EF" />
              </div>
              <div className="prog-half upa">
                <div className="flex-center">
                  <h1>{getTranslation(`home.program.program3.title`)}<br /><span>{getTranslation(`home.program.program3.title2`)}</span></h1>
                  <p>{getTranslation(`home.program.program3.para`)}</p>
                </div>
              </div>
            </div>

            <div className="prog-row mob-reverse">
              <div className="prog-half jca">
                <div className="flex-center">
                  <h1>{getTranslation(`home.program.program4.title`)}<br /><span>{getTranslation(`home.program.program4.title2`)}</span></h1>
                  <p>{getTranslation(`home.program.program4.para`)}</p>
                </div>
              </div>
              <div className="prog-half">
                <img src={jca} alt="EF" />
              </div>

            </div>

          </div>
        </div>
      </section>,
      <section className="section-div section-office">
        <div className="office-area">
          <div className="office-box">
            <h1>{getTranslation(`home.office.title`)}</h1>
            <div className="office-row desk-show">
              <div className="off60 pr8">
                <div className="offadd add1">
                  <div className="offAddBlk">
                    <h1>{getTranslation(`home.office.office1.address`)}</h1>
                    <p>{getTranslation(`home.office.office1.addressline1`)}</p>
                    <p>{getTranslation(`home.office.office1.addressline2`)}</p>
                    <a href={"tel:" + getTranslation(`home.office.office1.addressphn`)} className="telLink">Tel {getTranslation(`home.office.office1.addressphn`)}</a>
                    <p>{getTranslation(`home.office.office1.addressth`)}</p>
                    <p>{getTranslation(`home.office.office1.addresstime`)}</p>
                  </div>
                </div>
              </div>
              <div className="off40">
                <div className="offadd add2">
                  <div className="offAddBlk">
                    <h1>{getTranslation(`home.office.office2.address`)}</h1>
                    <p>{getTranslation(`home.office.office2.addressline1`)}</p>
                    <p>{getTranslation(`home.office.office2.addressline2`)}</p>
                    <a href={"tel:" + getTranslation(`home.office.office2.addressphn`)} className="telLink">Tel {getTranslation(`home.office.office2.addressphn`)}</a>
                    <p>{getTranslation(`home.office.office2.addressth`)}</p>
                    <p>{getTranslation(`home.office.office2.addresstime`)}</p>
                  </div>
                </div>
              </div>
              <div className="off40 pr8">
                <div className="offadd add3">
                  <div className="offAddBlk">
                    <h1>{getTranslation(`home.office.office3.address`)}</h1>
                    <p>{getTranslation(`home.office.office3.addressline1`)}</p>
                    <p>{getTranslation(`home.office.office3.addressline2`)}</p>
                    <a href={"tel:" + getTranslation(`home.office.office3.addressphn`)} className="telLink">Tel {getTranslation(`home.office.office3.addressphn`)}</a>
                    <p>{getTranslation(`home.office.office3.addressth`)}</p>
                    <p>{getTranslation(`home.office.office3.addresstime`)}</p>
                  </div>
                </div>
              </div>
              <div className="off60">
                <div className="offadd add4">
                  <div className="offAddBlk">
                    <h1>{getTranslation(`home.office.office4.address`)}</h1>
                    <p>{getTranslation(`home.office.office4.addressline1`)}</p>
                    <p>{getTranslation(`home.office.office4.addressline2`)}</p>
                    <a href={"tel:" + getTranslation(`home.office.office4.addressphn`)} className="telLink">Tel {getTranslation(`home.office.office4.addressphn`)}</a>
                    <p>{getTranslation(`home.office.office4.addressth`)}</p>
                    <p>{getTranslation(`home.office.office4.addresstime`)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*accordion*/}
          <div className="office-row mob-show">
            <Accordion atomic={true}>

              <AccordionItem title={getTranslation(`home.office.office1.address`)}>

                <div className="offAddBlk">
                  <h1>{getTranslation(`home.office.office1.address`)}</h1>
                  <p>{getTranslation(`home.office.office1.addressline1`)}</p>
                  <p>{getTranslation(`home.office.office1.addressline2`)}</p>
                  <a href={"tel:" + getTranslation(`home.office.office1.addressphn`)} className="telLink">Tel {getTranslation(`home.office.office1.addressphn`)}</a>
                  <p>{getTranslation(`home.office.office1.addressth`)}</p>
                  <p>{getTranslation(`home.office.office1.addresstime`)}</p>
                  <p style={{ height: "50px" }}></p>
                </div>

              </AccordionItem>

              <AccordionItem title={getTranslation(`home.office.office2.address`)}>
                <div className="offAddBlk">
                  <h1>{getTranslation(`home.office.office2.address`)}</h1>
                  <p>{getTranslation(`home.office.office2.addressline1`)}</p>
                  <p>{getTranslation(`home.office.office2.addressline2`)}</p>
                  <a href={"tel:" + getTranslation(`home.office.office2.addressphn`)} className="telLink">Tel {getTranslation(`home.office.office2.addressphn`)}</a>
                  <p>{getTranslation(`home.office.office2.addressth`)}</p>
                  <p>{getTranslation(`home.office.office2.addresstime`)}</p>
                  <p style={{ height: "50px" }}></p>
                </div>
              </AccordionItem>

              <AccordionItem title={getTranslation(`home.office.office3.address`)}>
                <div className="offAddBlk">
                  <h1>{getTranslation(`home.office.office3.name`)}</h1>
                  <p>{getTranslation(`home.office.office3.addressline1`)}</p>
                  <p>{getTranslation(`home.office.office3.addressline2`)}</p>
                  <a href={"tel:" + getTranslation(`home.office.office3.addressphn`)} className="telLink">Tel {getTranslation(`home.office.office3.addressphn`)}</a>
                  <p>{getTranslation(`home.office.office3.addressth`)}</p>
                  <p>{getTranslation(`home.office.office3.addresstime`)}</p>
                  <p style={{ height: "50px" }}></p>
                </div>
              </AccordionItem>

              <AccordionItem title={getTranslation(`home.office.office4.address`)}>
                <div className="offAddBlk">
                  <h1>{getTranslation(`home.office.office4.address`)}</h1>
                  <p>{getTranslation(`home.office.office4.addressline1`)}</p>
                  <p>{getTranslation(`home.office.office4.addressline2`)}</p>
                  <a href={"tel:" + getTranslation(`home.office.office4.addressphn`)} className="telLink">Tel {getTranslation(`home.office.office4.addressphn`)}</a>
                  <p>{getTranslation(`home.office.office4.addressth`)}</p>
                  <p>{getTranslation(`home.office.office4.addresstime`)}</p>
                  <p style={{ height: "50px" }}></p>
                </div>
              </AccordionItem>

            </Accordion>

          </div>
          <div className="ofc-btn-area">
            <a href={getTranslation(`contactus.allOffice.href`)} className="button offc-btn" target="_blank" rel="noopener noreferrer">{getTranslation(`home.office.cta`)}</a>
          </div>

        </div>
      </section >,
      <section className="section-div section-accred">
        <div className="accred-area">
          <div className="accred-box">
            <h1>{getTranslation(`home.partner.title`)}</h1>
            <div className="accred-row">

              <Slider {...settings}>

                {images.map(image => (
                  <li><img src={image} alt="EF" /></li>

                ))}
              </Slider>

            </div>
          </div>
        </div>
      </section>,
      <section className="section-div section-become-agent">
        <div className="become-agent-con">
          <div className="become-agent-row">
            <h1>{getTranslation(`home.agent.title`)}</h1>
            <p>{getTranslation(`home.agent.subtitle`)}</p>
            <Link to="/Register/" className="button agentBtn">{getTranslation(`home.agent.text`)}</Link>
          </div>
        </div>

        
      </section>
    ];
  }
}


