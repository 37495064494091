/* global EF */
import React, { Component } from 'react';
import './App.css';
import ScrollToTop from './ScrollToTop.js';
import AdaptiveHeader from './Header.js';
import Home from './Home.js';
import ContactUs from './ContactUs.js';
import SignIn from './SignIn.js';
import Agent from './Agent.js';
import Materials from './Materials.js';
import Courses from './Courses.js';
import Promos from './Promos.js';
import Destinations from './Destinations.js';
import PriceQuotations from './PriceQuotations.js';
import Register from './Register.js';
import OrderMaterial from './OrderMaterial.js';
import Footer from './Footer.js';
import NewYork from './NewYork.js';
import Boston from './Boston.js';
import Miami from './Miami.js';
import LosAngeles from './LosAngeles.js';
import Chicago from './Chicago.js';
import SanDiego from './SanDiego.js';
import SantaBarbara from './SantaBarbara.js';
import SanFrancisco from './SanFrancisco.js';
import Seattle from './Seattle.js';
import Honolulu from './Honolulu.js';
import Vancouver from './Vancouver.js';
import VancouverIsland from './VancouverIsland.js';
import Toronto from './Toronto.js';
import London from './London.js';
import LondonAldgate from './LondonAldgate.js';
import Bournemouth from './Bournemouth.js';
import Oxford from './Oxford.js';
import CambridgeClareCollege from './CambridgeClareCollege.js';
import Cambridge from './Cambridge.js';
import Brighton from './Brighton.js';
import Eastbourne from './Eastbourne.js';
import Bristol from './Bristol.js';
import Manchester from './Manchester.js';
import Dublin from './Dublin.js';
import StJulians from './StJulians.js';
import CapeTown from './CapeTown.js';
import Sydney from './Sydney.js';
import Perth from './Perth.js';
import Brisbane from './Brisbane.js';
import Auckland from './Auckland.js';
import Singapore from './Singapore.js';
import Paris from './Paris.js';
import Nice from './Nice.js';
import StRaphael from './StRaphael.js';
import Berlin from './Berlin.js';
import Munich from './Munich.js';
import Madrid from './Madrid.js';
import Barcelona from './Barcelona.js';
import PlayaTamarindo from './PlayaTamarindo.js';
import Lisbon from './Lisbon.js';
import Rome from './Rome.js';
import Dubai from './Dubai.js';
import Shanghai from './Shanghai.js';
import Seoul from './Seoul.js';
import Tokyo from './Tokyo.js';
import Moscow from './Moscow.js';
import Malaga from './Malaga.js';
import PlayaDelCarman from './PlayaDelCarman.js';
import ShortTerm from './ShortTerm.js';
import LongTerm from './LongTerm.js';
import Accomodation from './Accomodation.js';
import Thankyou from './Thankyou.js';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

const axios = require('axios');
// eslint-disable-next-line

 

class App extends Component {
  constructor(props) {
    super(props);
    this.state = ({
    
      loading: true,
      
      
    });
   
    
    //EF.globals.marketCode = EF.getCurrentMarketCode();
    //EF.globals.marketCode = EF.isLocal() ? 'we' : EF.getCurrentMarketCode();
    //EF.globals.marketCode = 'ar';
    EF.globals.campaignName = 'GH_AgentsPortal';

    EF.addStandardClasses();
    
  }
  
  

  componentDidMount() {
    
    function getQueryVariable(variable){
        var query = window.location.search.substring(1);
        //console.log(query);
        var vars = query.split("&");
        //console.log(vars); 
        for (var i=0;i<vars.length;i++) {
         
                    var pair = vars[i].split("=");
                    
                  
                    EF.globals.marketCode=pair[1];  
                    //console.log(EF.globals.marketCode);          
        if(pair[0] === variable){return pair[1];}
        
        
         }
        
           
}


getQueryVariable('lang');




axios.get(`${EF.globals.marketCode}/locale.js`).then(()=>{
  console.log("found");
  EF.getScript(`${EF.globals.marketCode}/locale.js`).then(() => {

    this.setState({
      ...this.state,
      loading: false,
     
    });
  });
}).catch((error) => {
  console.log(error);
  EF.getScript('we/locale.js').then(() => {

    this.setState({
      ...this.state,
      loading: false,
      
    });
  });
});





    
  
  
   
   
  }
  
  

  render() {
    
    if (this.state.loading) {
      return (<div id="overlay"><div class="loaderCenter"><div class="loaderCon"><div class="col-full"><div class="loaderimag"><img src="https://efdigitalmarketing.github.io/templates/assets/logos/EFlogo_vector-white.svg" alt="EF"/><div id="loadertxt">Loading...</div></div><div id="progress"></div><div id="progstat"></div></div></div></div></div>);
    }
    
    if(EF.globals.marketCode==="thank"){
     
      /* return(<Router><Redirect to="/Thankyou" /></Router>);  */
      return(<Router><ScrollToTop><div><AdaptiveHeader /><div id="content"><Thankyou /></div><Footer /></div></ScrollToTop></Router>);
      
    }
   
   

    return (<Router>
      <ScrollToTop>
        <div>
          <AdaptiveHeader />
          <div id="content">
            <Route exact path={"/"} component={Home} />
            <Route path={"/ContactUs/"} component={ContactUs} />
            <Route path={"/SignIn/"} component={SignIn} />
            <Route path={"/Agent/"} component={Agent} />
            <Route path={"/Materials/"} component={Materials} />
            <Route path={"/Courses/"} component={Courses} />
            <Route path={"/Promos/"} component={Promos} />
            <Route path={"/Destinations/"} component={Destinations} />
            <Route path={"/PriceQuotations/"} component={PriceQuotations} />
            <Route path={"/Register/"} component={Register} />
            <Route path={"/OrderMaterial/"} component={OrderMaterial} />
            <Route path={"/NewYork/"} component={NewYork} />
            <Route path={"/Boston/"} component={Boston} />
            <Route path={"/Miami/"} component={Miami} />
            <Route path={"/LosAngeles/"} component={LosAngeles} />
            <Route path={"/Chicago/"} component={Chicago} />
            <Route path={"/SanDiego/"} component={SanDiego} />
            <Route path={"/SantaBarbara/"} component={SantaBarbara} />
            <Route path={"/SanFrancisco/"} component={SanFrancisco} />
            <Route path={"/Seattle/"} component={Seattle} />
            <Route path={"/Honolulu/"} component={Honolulu} />
            <Route path={"/Vancouver/"} component={Vancouver} />
            <Route path={"/VancouverIsland/"} component={VancouverIsland} />
            <Route path={"/Toronto/"} component={Toronto} />
            <Route path={"/London/"} component={London} />
            <Route path={"/LondonAldgate/"} component={LondonAldgate} />
            <Route path={"/Bournemouth/"} component={Bournemouth} />
            <Route path={"/Oxford/"} component={Oxford} />
            <Route path={"/CambridgeClareCollege/"} component={CambridgeClareCollege} />
            <Route path={"/Cambridge/"} component={Cambridge} />
            <Route path={"/Brighton/"} component={Brighton} />
            <Route path={"/Eastbourne/"} component={Eastbourne} />
            <Route path={"/Bristol/"} component={Bristol} />
            <Route path={"/Manchester/"} component={Manchester} />
            <Route path={"/Dublin/"} component={Dublin} />
            <Route path={"/StJulians/"} component={StJulians} />
            <Route path={"/CapeTown/"} component={CapeTown} />
            <Route path={"/Sydney/"} component={Sydney} />
            <Route path={"/Perth/"} component={Perth} />
            <Route path={"/Brisbane/"} component={Brisbane} />
            <Route path={"/Auckland/"} component={Auckland} />
            <Route path={"/Singapore/"} component={Singapore} />
            <Route path={"/Paris/"} component={Paris} />
            <Route path={"/Nice/"} component={Nice} />
            <Route path={"/StRaphael/"} component={StRaphael} />
            <Route path={"/Berlin/"} component={Berlin} />
            <Route path={"/Munich/"} component={Munich} />
            <Route path={"/Madrid/"} component={Madrid} />
            <Route path={"/Barcelona/"} component={Barcelona} />
            <Route path={"/PlayaTamarindo/"} component={PlayaTamarindo} />
            <Route path={"/Lisbon/"} component={Lisbon} />
            <Route path={"/Rome/"} component={Rome} />
            <Route path={"/Dubai/"} component={Dubai} />
            <Route path={"/Shanghai/"} component={Shanghai} />
            <Route path={"/Seoul/"} component={Seoul} />
            <Route path={"/Tokyo/"} component={Tokyo} />
            <Route path={"/Moscow/"} component={Moscow} />
            <Route path={"/Malaga/"} component={Malaga} />
            <Route path={"/PlayaDelCarman/"} component={PlayaDelCarman} />
            <Route path={"/ShortTerm/"} component={ShortTerm} />
            <Route path={"/LongTerm/"} component={LongTerm} />
        <Route path={"/Accomodation/"} component={Accomodation} />
        
          <Route path={"/Thankyou/"} component={Thankyou} />
        
        
          </div>
          <Footer />
        </div>
      </ScrollToTop>
    </Router>

    );
  }
}

export default App;