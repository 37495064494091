import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from 'react-light-accordion';
import Slider from "react-slick";
import logo1 from "./images/accreditions/logo_1.jpg";
import logo2 from "./images/accreditions/logo_2.jpg";
import logo3 from "./images/accreditions/logo_3.jpg";
import logo4 from "./images/accreditions/logo_4.jpg";
import logo5 from "./images/accreditions/logo_5.jpg";
import logo6 from "./images/accreditions/logo_6.jpg";
import logo7 from "./images/accreditions/logo_7.jpg";
import logo8 from "./images/accreditions/logo_8.jpg";
import logo9 from "./images/accreditions/logo_9.jpg";
import logo10 from "./images/accreditions/logo_10.jpg";
import fire from './config/Fire';
import { getTranslation } from './utils';


export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      user: null,
      images: [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10],
    });
    this.authListener = this.authListener.bind(this);

  }

  componentDidMount() {
    this.authListener();
  }




  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.setState({ user });
        localStorage.setItem('user', user.uid);
      } else {
        this.setState({ user: null });
        localStorage.removeItem('user');
      }
    });
  }
  render() {
    const { images } = this.state;
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return [
      <section className="section-div contactUs-section"></section>,
      <section className="section-div contactUs-detail-section">
        <div className="contactUs-detail-inner">
          <div className="contactUs-detail-con">
            <h1>{getTranslation(`contactus.hero.title`)}</h1>
            <p>{getTranslation(`contactus.hero.subtitle`)}</p>
            {!this.state.user && <div className="contactUs-btn-area">
              <Link to="/SignIn/" className="button detail-btn detloginBtn">{getTranslation(`home.hero.cta1Text`)}</Link>
              <Link to="/Register/" className="button detail-btn detregBtn">{getTranslation(`home.hero.cta2Text`)}</Link>

            </div>}
          </div>
        </div>
      </section>,

      <section class="section-div section-office">
        <div class="office-area">
          <div class="office-box">
            <h1>{getTranslation(`contactus.office.title`)}</h1>
            <div class="office-row desk-show">
              <div class="off60 pr8">
                <div class="offadd add1">
                  <div class="offAddBlk">
                    <h1>{getTranslation(`contactus.office.office1.address`)}</h1>
                    <p>{getTranslation(`contactus.office.office1.addressline1`)}</p>
                    <p>{getTranslation(`contactus.office.office1.addressline2`)}</p>
                    <a href={"tel:" + getTranslation(`contactus.office.office1.addressphn`)} class="telLink">Tel {getTranslation(`contactus.office.office1.addressphn`)}</a>
                    <p>{getTranslation(`contactus.office.office1.addressth`)}</p>
                    <p>{getTranslation(`contactus.office.office1.addresstime`)}</p>
                  </div>
                </div>
              </div>
              <div class="off40">
                <div class="offadd add2">
                  <div class="offAddBlk">
                    <h1>{getTranslation(`contactus.office.office2.address`)}</h1>
                    <p>{getTranslation(`contactus.office.office2.addressline1`)}</p>
                    <p>{getTranslation(`contactus.office.office2.addressline2`)}</p>
                    <a href={"tel:" + getTranslation(`contactus.office.office2.addressphn`)} class="telLink">Tel {getTranslation(`contactus.office.office2.addressphn`)}</a>
                    <p>{getTranslation(`contactus.office.office2.addressth`)}</p>
                    <p>{getTranslation(`contactus.office.office2.addresstime`)}</p>
                  </div>
                </div>
              </div>
              <div class="off40 pr8">
                <div class="offadd add3">
                  <div class="offAddBlk">
                    <h1>{getTranslation(`contactus.office.office3.address`)}</h1>
                    <p>{getTranslation(`contactus.office.office3.addressline1`)}</p>
                    <p>{getTranslation(`contactus.office.office3.addressline2`)}</p>
                    <a href={"tel:" + getTranslation(`contactus.office.office3.addressphn`)} class="telLink">Tel {getTranslation(`contactus.office.office3.addressphn`)}</a>
                    <p>{getTranslation(`contactus.office.office3.addressth`)}</p>
                    <p>{getTranslation(`contactus.office.office3.addresstime`)}</p>
                  </div>
                </div>
              </div>
              <div class="off60">
                <div class="offadd add4">
                  <div class="offAddBlk">
                    <h1>{getTranslation(`contactus.office.office4.address`)}</h1>
                    <p>{getTranslation(`contactus.office.office4.addressline1`)}</p>
                    <p>{getTranslation(`contactus.office.office4.addressline2`)}</p>
                    <a href={"tel:" + getTranslation(`contactus.office.office4.addressphn`)} class="telLink">Tel {getTranslation(`contactus.office.office4.addressphn`)}</a>
                    <p>{getTranslation(`contactus.office.office4.addressth`)}</p>
                    <p>{getTranslation(`contactus.office.office4.addresstime`)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*accordion*/}
          <div class="office-row mob-show">
            <Accordion atomic={true}>

              <AccordionItem title={getTranslation(`contactus.office.office1.address`)}>

                <div class="offAddBlk">
                  <h1>{getTranslation(`contactus.office.office1.address`)}</h1>
                  <p>{getTranslation(`contactus.office.office1.addressline1`)}</p>
                  <p>{getTranslation(`contactus.office.office1.addressline2`)}</p>
                  <a href={"tel:" + getTranslation(`contactus.office.office1.addressphn`)} class="telLink">Tel {getTranslation(`contactus.office.office1.addressphn`)}</a>
                  <p>{getTranslation(`contactus.office.office1.addressth`)}</p>
                  <p>{getTranslation(`contactus.office.office1.addresstime`)}</p>
                  <p style={{ height: "50px" }}></p>
                </div>

              </AccordionItem>

              <AccordionItem title={getTranslation(`contactus.office.office2.address`)}>
                <div class="offAddBlk">
                  <h1>{getTranslation(`contactus.office.office2.address`)}</h1>
                  <p>{getTranslation(`contactus.office.office2.addressline1`)}</p>
                  <p>{getTranslation(`contactus.office.office2.addressline2`)}</p>
                  <a href={"tel:" + getTranslation(`contactus.office.office2.addressphn`)} class="telLink">Tel {getTranslation(`contactus.office.office2.addressphn`)}</a>
                  <p>{getTranslation(`contactus.office.office2.addressth`)}</p>
                  <p>{getTranslation(`contactus.office.office2.addresstime`)}</p>
                  <p style={{ height: "50px" }}></p>
                </div>
              </AccordionItem>

              <AccordionItem title={getTranslation(`contactus.office.office3.address`)}>
                <div class="offAddBlk">
                  <h1>{getTranslation(`contactus.office.office3.address`)}</h1>
                  <p>{getTranslation(`contactus.office.office3.addressline1`)}</p>
                  <p>{getTranslation(`contactus.office.office3.addressline2`)}</p>
                  <a href={"tel:" + getTranslation(`contactus.office.office3.addressphn`)} class="telLink">Tel {getTranslation(`contactus.office.office3.addressphn`)}</a>
                  <p>{getTranslation(`contactus.office.office3.addressth`)}</p>
                  <p>{getTranslation(`contactus.office.office3.addresstime`)}</p>
                  <p style={{ height: "50px" }}></p>
                </div>
              </AccordionItem>

              <AccordionItem title={getTranslation(`contactus.office.office4.address`)}>
                <div class="offAddBlk">
                  <h1>{getTranslation(`contactus.office.office4.address`)}</h1>
                  <p>{getTranslation(`contactus.office.office4.addressline1`)}</p>
                  <p>{getTranslation(`contactus.office.office4.addressline2`)}</p>
                  <a href={"tel:" + getTranslation(`contactus.office.office4.addressphn`)} class="telLink">Tel {getTranslation(`contactus.office.office4.addressphn`)}</a>
                  <p>{getTranslation(`contactus.office.office4.addressth`)}</p>
                  <p>{getTranslation(`contactus.office.office4.addresstime`)}</p>
                  <p style={{ height: "50px" }}></p>
                </div>
              </AccordionItem>

            </Accordion>

          </div>
          <div className="ofc-btn-area">
            <a href="https://www.ef.com/contact/world-wide/" className="button offc-btn" target="_blank" rel="noopener noreferrer">{getTranslation(`contactus.allOffice.text`)}</a>
          </div>
        </div>
      </section >,
      <section class="section-div section-accred">
        <div class="accred-area">
          <div class="accred-box">
            <h1>{getTranslation(`contactus.partner.title`)}</h1>
            <div class="accred-row">
              <Slider {...settings}>

                {images.map(image => (
                  <li><img src={image} alt="EF" /></li>

                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>,
     <section className="section-div section-become-agent">
     <div className="become-agent-con">
       <div className="become-agent-row">
         <h1>{getTranslation(`home.agent.title`)}</h1>
         <p>{getTranslation(`home.agent.subtitle`)}</p>
         <Link to="/Register/" className="button agentBtn">{getTranslation(`home.agent.text`)}</Link>
       </div>
     </div>

     
   </section>

    ];
  }
}


