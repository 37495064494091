import React, { Component } from 'react';
import Slider from "react-slick";
import tick from "./images/tick.png";
import fact from "./factsheet/Lisbon.pdf";
import sl1 from "./images/lisbon/gallery1.jpg";
import sl2 from "./images/lisbon/gallery2.jpg";
import sl3 from "./images/lisbon/gallery3.jpg";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactPlayer from 'react-player';
import { getTranslation } from './utils';



export default class Lisbon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [sl1, sl2, sl3],
      current: '',
      
      isOpen: false,
    }
  }
  handleClickImage = (e, image) => {
    e && e.preventDefault();

    this.setState({
      current: image,


    })
  }

  handleCloseModal = (e) => {
    e && e.preventDefault();

    this.setState({
      current: '',

    })
  }
  render() {
    const { images, current } = this.state;
    
    var settings = {
      dots: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false,

          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerMode: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerMode: true,
          }
        }
      ]
    };


    return [
      <section className="section-div school-hero lisbon_hero">
        <div className="school-hero-inner">
          <div className="school-hero-content">
            <h1>{getTranslation(`schools.lisbon.SchoolName`)}</h1>
            <p>{getTranslation(`schools.lisbon.schoolAddressLine1`)}</p><br />
            <p>{getTranslation(`schools.lisbon.schoolAddressLine2`)}</p>
          </div>
        </div>
      </section>,
      <section className="section-div section-scHighlight">
        <div className="scArea">
          <div className="col-full">
           
            <div className="scCon">
              <h1>{getTranslation(`schools.lisbon.SchoolHighlightTitle`)}</h1>
              <div className="highlighList">
                <ul>
                  <li>
                    <span className="tickImg">
                      <img src={tick} alt="EF" />
                    </span>
                    <span className="listCon">{getTranslation(`schools.lisbon.schoolHighlight1`)}</span>
                  </li>

                  <li>
                    <span className="tickImg">
                      <img src={tick} alt="EF" />
                    </span>
                    <span className="listCon">{getTranslation(`schools.lisbon.schoolHighlight2`)}</span>
                  </li>

                  <li>
                    <span className="tickImg">
                      <img src={tick} alt="EF" />
                    </span>
                    <span className="listCon">{getTranslation(`schools.lisbon.schoolHighlight3`)}</span>
                  </li>

            <li>
             <span className="tickImg">
              <img src={tick} alt="EF" />
             </span>
             <span className="listCon">{getTranslation(`schools.lisbon.schoolHighlight4`)}</span>
            </li>

            <li>
             <span className="tickImg">
              <img src={tick} alt="EF" />
             </span>
             <span className="listCon">{getTranslation(`schools.lisbon.schoolHighlight5`)}</span>
            </li>



                </ul>
           <div className="sc-btn-area">
            <a href={fact} className="sc-acc-btn button" target="_blank" rel="noopener noreferrer">{getTranslation(`schools.cta`)}</a>
           </div>
           
              </div>
            </div>
          </div>
        </div>
      </section>,
      
      <section className="section-div section-scGallery">
        <div className="scGallery-area">
          <div className="scGallery-box">
            <h1>{getTranslation(`schools.GalleryTitle`)}</h1>
            <div className="scGallery-row">
              <Slider {...settings}>
                {images.map(image => (
                  <img src={image} onClick={e => this.handleClickImage(e, image)} alt="EF" />

                ))}
              </Slider>
              {current &&
                <Lightbox
                  mainSrc={current}
                  onCloseRequest={this.handleCloseModal}
                  enableZoom={false}
                />
              }
            </div>
          </div>
        </div>
      </section>,
      <section className="section-div section-scVideo">
        <div className="scVideo-area">
          <div className="scVideo-box">
            <div className="video-block">
              {/* <iframe title="School Video" src={getTranslation(`schools.lisbon.schoolYoutubeLink`)} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          <ReactPlayer
           className="react-player"
           url={getTranslation(`schools.lisbon.schoolYoutubeLink`)}
           playing
           light={true}
           controls
           width="100%"
           height="100%"
          />
            </div>
          </div>
        </div>
      </section>,
      



    ];
  }
}


