import React, { Component } from 'react';
import { getTranslation } from './utils';

export default class Register extends Component {
  render() {
    return [
      <section class="section-div register-hero">
        <div class="register-hero-inner">
          <div class="register-hero-content">
            <h1>{getTranslation(`Register.hero.title`)}</h1>
            <p>{getTranslation(`Register.hero.subtitle`)}</p>
          </div>
        </div>
      </section>,
      <section class=" section-div section-register">
        <div class="register-area">
          <div class="register-box">
            <iframe title="Register Form"
              allowTransparency="true"
              frameborder="0"
              scrolling="no"
              src="https://efeducation.wufoo.com/embed/z1ntpn7t1xcpdok/">
              <a href="https://efeducation.wufoo.com/forms/z1ntpn7t1xcpdok/">
                Fill out my Wufoo form!
                          </a>
            </iframe>

          </div>
        </div>
      </section>

    ];
  }
}


