/* global EF */
function getTranslation(str = 'undefined', obj = EF.globals.i18n) {
    const splitted = str.split('.');
    if (splitted.length > 1) {
        const key = splitted.shift();
        return getTranslation(splitted.join('.'), obj[key]);
    } // if
    if (obj[splitted[0]]) {
        return obj[splitted[0]];
    } // if
    console.log(`%cNo translation found for "${str}"`, 'color: #f4a742'); // eslint-disable-line no-console
    return null;
} // getTranslation

export {
    getTranslation
};
