import React, {Component} from 'react';
import {getTranslation} from './utils';
import homestay from "./images/homestay.jpg";
import resid from "./images/resid.jpg";
import campus from "./images/campus.jpg";





export default class Accomodation extends Component {
 

 




 render() {
  

  return [
   <section class="section-div acc-hero">
    <div class="acc-hero-inner">
     <div class="acc-hero-content">
      <h1>{getTranslation(`Accomodation.hero.title`)}</h1>
      <p>{getTranslation(`Accomodation.hero.subtitle`)}</p>
     </div>
    </div>
   </section>,
   <div class="acc-parent">
    <section class="section-div section-acc">

    
      <div class="acc-box">
      
      <div class="acc-row">
       <div class="acc-half">
        <img src={homestay} alt="EF" />
        </div>
       <div class="acc-half homestay">
        <div class="acc-flex-center">
         <h1>{getTranslation(`Accomodation.acc.acc1.title`)}</h1>
         <p>{getTranslation(`Accomodation.acc.acc1.para`)}</p>
         </div>
        </div>
       </div>

      <div class="acc-row acc-mob-reverse">
       <div class="acc-half resid">
        <div class="acc-flex-center">
         <h1>{getTranslation(`Accomodation.acc.acc2.title`)}</h1>
         <p>{getTranslation(`Accomodation.acc.acc2.para`)}</p>
         </div>
        </div>
       <div class="acc-half">
        <img src={resid} alt="EF" />
        </div>

       </div>

      <div class="acc-row">
       <div class="acc-half">
        <img src={campus} alt="EF" />
        </div>
       <div class="acc-half campus">
        <div class="acc-flex-center">
         <h1>{getTranslation(`Accomodation.acc.acc3.title`)}</h1>
         <p>{getTranslation(`Accomodation.acc.acc3.para`)}</p>
         </div>
        </div>
       </div>


      </div>
    

    </section>


   </div>





  ];
 }
}


