import React, { Component } from 'react';
import ico1 from "./images/reachout.svg";
import ico2 from "./images/setup.svg";
export default class Thankyou extends Component {
  render() {
    return [
      <section class="section-div thank-hero">
        <div class="thank-hero-inner">
          <div class="thank-hero-content">
            <h1>Thank you very much for submitting your registration. </h1>
           
          </div>
        </div>
      </section>,

      <section class="section-div section-thank">
        <div class="thank-area">
          <div class="thank-box">
            
            <div class="thank-row">
              
            <img src={ico1} alt="EF" />
                <p>If you are already working with us, your regional manager will shortly reach out to you with the log in details so you can access the portal. </p>
             

            </div>

            <div class="thank-row">
            
            <img src={ico2} alt="EF" />
            <p>If we do not already have a partnership, 
our team will contact you to set it up and send you the necessary information and documents to start our collaboration.</p>
            </div>

           
          </div>
        </div>
      </section>,
     <section className="section-div section-become-agent thankbg">
     <div className="become-agent-con">
       <div className="become-agent-row">
         <h1>While you are waiting for us to send you the log in details, do not hesitate to consult our website on </h1>
        
        
         <a href="https://www.ef.com" className="button agentBtn" target="_blank" rel="noopener noreferrer">www.ef.com</a>
       </div>
     </div>

     
   </section>
      


    ];
  }
}


