import React, { Component } from 'react';
import { Link } from "react-router-dom";
import fire from './config/Fire';
import { getTranslation } from './utils';

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      email: '',
      password: '',
      error: ''
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  login(e) {
    e.preventDefault();
    fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(this.onLoginSuccess.bind(this))
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);

        let errorMessage = error.message;
        this.onLoginFailure.bind(this)(errorMessage)

      });
  }
  onLoginSuccess() {
    //window.location.href = "/hub/agents-portal/Agent/";
    this.props.history.push("/Agent/");
  }
  onLoginFailure(errorMessage) {
    this.setState({ error: errorMessage })
  }
  render() {

    return [
      <section class="section-div signin-hero">
        <div class="signin-hero-inner">
          <div class="signin-hero-content">
            <h1>{getTranslation(`SignIn.hero.title`)}</h1>
            <p>{getTranslation(`SignIn.hero.subtitle`)}</p>
          </div>
        </div>
      </section>,
      <section class=" section-div section-signin">
        <div class="signin-area">
          <div class="signin-box">
            <form>
              <div class="form-group">
                <label for="email">{getTranslation(`SignIn.form.email`)}</label>
                <input value={this.state.email} onChange={this.handleChange} type="email" name="email" class="form-control" required />

              </div>
              <div class="form-group">
                <label for="password">{getTranslation(`SignIn.form.password`)}</label>
                <input value={this.state.password} onChange={this.handleChange} type="password" name="password" class="form-control" required />
              </div>
              <div class="formError">
                {this.state.error}
              </div>
              <button type="submit" onClick={this.login} class="button logBtn">{getTranslation(`SignIn.form.cta1txt`)}</button>
              <Link to="/Register/" className="button logBtn">{getTranslation(`SignIn.form.cta2txt`)}</Link>
            </form>

          </div>
        </div>
      </section>
    ];
  }
}


