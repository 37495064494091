import React, { Component } from 'react';
import { getTranslation } from './utils';
export default class Promos extends Component {
  render() {
    return [
      <section class="section-div promos-hero">
        <div class="promos-hero-inner">
          <div class="promos-hero-content">
            <h1>{getTranslation(`PromoUpdate.title`)}</h1>
            <p>{getTranslation(`PromoUpdate.subtitile`)}</p>
          </div>
        </div>
      </section>,

      <section class="section-div section-promos">
        <div class="promos-area">
          <div class="promos-box">
            <h1>{getTranslation(`PromoUpdate.promos.promoTitle`)}</h1>
            <div class="promos-row">
              <a href={getTranslation(`PromoUpdate.promos.promo1href`)} target="_blank" rel="noopener noreferrer">
                <h1>{getTranslation(`PromoUpdate.promos.promo1Title`)}</h1>
                <p><span>{getTranslation(`PromoUpdate.promos.promo1para`)}</span><span class="rMore">{getTranslation(`PromoUpdate.promos.promoRead`)}</span></p>
              </a>

            </div>

            <div class="promos-row">
              <a href={getTranslation(`PromoUpdate.promos.promo2href`)} target="_blank" rel="noopener noreferrer">
                <h1>{getTranslation(`PromoUpdate.promos.promo2Title`)}</h1>
                <p><span>{getTranslation(`PromoUpdate.promos.promo2para`)}</span><span class="rMore">{getTranslation(`PromoUpdate.promos.promoRead`)}</span></p>
              </a>

            </div>

           {/*  <div class="promos-row">
              <a href={getTranslation(`PromoUpdate.promos.promo3href`)} target="_blank" rel="noopener noreferrer">
                <h1>{getTranslation(`PromoUpdate.promos.promo3Title`)}</h1>
                <p><span>{getTranslation(`PromoUpdate.promos.promo3para`)}</span><span class="rMore">{getTranslation(`PromoUpdate.promos.promoRead`)}</span></p>
              </a>

            </div>

            <div class="promos-row">
              <a href={getTranslation(`PromoUpdate.promos.promo4href`)} target="_blank" rel="noopener noreferrer">
                <h1>{getTranslation(`PromoUpdate.promos.promo4Title`)}</h1>
                <p><span>{getTranslation(`PromoUpdate.promos.promo4para`)}</span><span class="rMore">{getTranslation(`PromoUpdate.promos.promoRead`)}</span></p>
              </a>

            </div> */}
          </div>
        </div>
      </section>,
      <section class="section-div section-updates">
        <div class="update-area">
          <div class="update-box">
            <h1>{getTranslation(`PromoUpdate.update.updateTitle`)}</h1>
            <div class="update-row">
              <h1>{getTranslation(`PromoUpdate.update.update1Title`)}</h1>
              <p>{getTranslation(`PromoUpdate.update.update1para`)}</p>
            </div>
            <div class="update-row">
              <h1>{getTranslation(`PromoUpdate.update.update2Title`)}</h1>
              <p>{getTranslation(`PromoUpdate.update.update2para`)}</p>

            </div>
            <div class="update-row">
              <h1>{getTranslation(`PromoUpdate.update.update3Title`)}</h1>
              <p>{getTranslation(`PromoUpdate.update.update3para`)}</p>
            </div>
            <div class="update-row">
              <h1>{getTranslation(`PromoUpdate.update.update4Title`)}</h1>
              <p>{getTranslation(`PromoUpdate.update.update4para`)}</p>
            </div>

          </div>
        </div>
      </section>


    ];
  }
}


