import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { getTranslation } from './utils';

export default class Agent extends Component {
  render() {
    return [

      <section class="section-div agent-hero">
        <div class="agent-hero-inner">
          <div class="agent-hero-content">
            <h1>{getTranslation(`agentpage.hero.title`)}</h1>
            <p>{getTranslation(`agentpage.hero.subtitle`)}</p>
          </div>
        </div>
      </section>,
      <section class=" section-div section-material">
        <div class="material-area">
          <div class="material-box">
            <ul>
              <li>
                <Link to="/Destinations/">
                  <div class="material-block">
                    <div className="matImg matDes"></div>
                  <div class="materialCon">
                      <h1>{getTranslation(`agentpage.card.card1Title`)}</h1>
                      <p>{getTranslation(`agentpage.card.card1Des`)}</p>
                    </div>
                    </div>
                </Link>
              </li>
              <li>
                <Link to="/Courses/">
                  <div class="material-block ">
                  <div className="matImg matCourse"></div>
                    <div class="materialCon">
                      <h1>{getTranslation(`agentpage.card.card2Title`)}</h1>
                      <p>{getTranslation(`agentpage.card.card2Des`)}</p>
                    </div>
                    </div>
                </Link>
              </li>
              <li>
                <Link to="/PriceQuotations/">
                  <div class="material-block ">
                  <div className="matImg matPQ"></div>
                    <div class="materialCon">
                      <h1>{getTranslation(`agentpage.card.card3Title`)}</h1>
                      <p>{getTranslation(`agentpage.card.card3Des`)}</p>
                    </div>
                    </div>
                </Link>
              </li>
              <li>
                <Link to="/Materials/">
                  <div class="material-block "> 
                  <div className="matImg matM"></div>
                    <div class="materialCon">
                      <h1>{getTranslation(`agentpage.card.card4Title`)}</h1>
                      <p>{getTranslation(`agentpage.card.card4Des`)}</p>
                    </div>
                    </div>
                </Link>
              </li>
              <li>
                <Link to="/Promos/">
                  <div class="material-block ">
                  <div className="matImg matPro"></div>
                    <div class="materialCon">
                      <h1>{getTranslation(`agentpage.card.card5Title`)}</h1>
                      <p>{getTranslation(`agentpage.card.card5Des`)}</p>
                    </div>
                    </div>
                </Link>
              </li>
              <li>
                <Link to="/OrderMaterial/">
                  <div class="material-block">
                  <div className="matImg matOrder"></div>
                    <div class="materialCon">
                      <h1>{getTranslation(`agentpage.card.card6Title`)}</h1>
                      <p>{getTranslation(`agentpage.card.card6Des`)}</p>
                    </div>
                    </div>
                </Link>
              </li>
          <li>
           <Link to="/Accomodation/">
            <div class="material-block">
            <div className="matImg matAcc"></div>
             <div class="materialCon">
              <h1>{getTranslation(`agentpage.card.card7Title`)}</h1>
              <p>{getTranslation(`agentpage.card.card7Des`)}</p>
             </div>
            </div>
           </Link>
          </li>
            </ul>
          </div>
        </div>
      </section>

    ];
  }
}


