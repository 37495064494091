import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from 'react-light-accordion';
import { getTranslation } from './utils';

const options1 = [
  {
    type: 'group', name: 'US', items: [
      { value: '/NewYork/', label: 'New York' },
      { value: '/Boston/', label: 'Boston' },
      { value: '/Miami/', label: 'Miami Beach' },
      { value: '/Chicago/', label: 'Chicago' },
      { value: '/SanDiego/', label: 'San Diego' },
      { value: '/SantaBarbara/', label: 'Santa Barbara' },
      { value: '/SanFrancisco/', label: 'San Francisco' },
      { value: '/Seattle/', label: 'Seattle' },
      { value: '/Honolulu/', label: 'Honolulu' },

    ]
  },
  {
    type: 'group', name: 'Canada', items: [
      { value: '/Vancouver/', label: 'Vancouver' },
      { value: '/VancouverIsland/', label: 'Vancouver Island' },
      { value: '/Toronto/', label: 'Toronto' },

    ]
  },
  {
    type: 'group', name: 'UK', items: [
      { value: '/London/', label: 'London' },
      { value: '/LondonAldgate/', label: 'London Aldgate' },
      { value: '/Bournemouth/', label: 'Bournemouth' },
      { value: '/Oxford/', label: 'Oxford' },
      { value: '/CambridgeClareCollege/', label: 'Cambridge Clare College' },
      { value: '/Cambridge/', label: 'Cambridge' },
      { value: '/Brighton/', label: 'Brighton' },
      { value: '/Eastbourne/', label: 'Eastbourne' },
      { value: '/Bristol/', label: 'Bristol' },
      { value: '/Manchester/', label: 'Manchester' },
    ]
  },
  {
    type: 'group', name: 'Ireland', items: [
      { value: '/Dublin/', label: 'Dublin' },

    ]
  },
  {
    type: 'group', name: 'Malta', items: [
      { value: '/StJulians/', label: 'St Julians' },

    ]
  },
  {
    type: 'group', name: 'South Africa', items: [
      { value: '/CapeTown/', label: 'Cape Town' },

    ]
  },
  {
    type: 'group', name: 'Australia', items: [
      { value: '/Sydney/', label: 'Sydney' },
      { value: '/Perth/', label: 'Perth' },
      { value: '/Brisbane/', label: 'Brisbane' },
    ]
  },
  {
    type: 'group', name: 'New Zealand', items: [
      { value: '/Auckland/', label: 'Auckland' },

    ]
  },
  {
    type: 'group', name: 'Singapore', items: [
      { value: '/Singapore/', label: 'Singapore' },

    ]
  },
  {
    type: 'group', name: 'France', items: [
      { value: '/Paris/', label: 'Paris' },
      { value: '/Nice/', label: 'Nice' },
      { value: '/StRaphael/', label: 'St Raphael' },
    ]
  },
  {
    type: 'group', name: 'Germany', items: [
      { value: '/Berlin/', label: 'Berlin' },
      { value: '/Munich/', label: 'Munich' },
    ]
  },
  {
    type: 'group', name: 'Spain', items: [
      { value: '/Madrid/', label: 'Madrid' },
      { value: '/Malaga/', label: 'Malaga' },
      { value: '/Barcelona/', label: 'Barcelona' },

    ]
  },
  {
    type: 'group', name: 'Costa Rica', items: [
      
      { value: '/PlayaTamarindo/', label: 'Playa Tamarindo' },

    ]
  },
  {
    type: 'group', name: 'Portugal', items: [
      { value: '/Lisbon/', label: 'Lisbon' },

    ]
  },
  {
    type: 'group', name: 'Italy', items: [
      { value: '/Rome/', label: 'Rome' },

    ]
  },
  {
    type: 'group', name: 'United Arab Emirates', items: [
      { value: '/Dubai/', label: 'Dubai' },

    ]
  },
  {
    type: 'group', name: 'China', items: [
      { value: '/Shanghai/', label: 'Shanghai' },

    ]
  },
  {
    type: 'group', name: 'Japan', items: [
      { value: '/Tokyo/', label: 'Tokyo' },

    ]
  },
  {
    type: 'group', name: 'South Korea', items: [
      { value: '/Seoul/', label: 'Seoul' },

    ]
  }
]



export default class Destinations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected1: '',
     
    }
    this._onSelect1 = this._onSelect1.bind(this)
   
  }

  _onSelect1(option) {
    this.setState({ selected1: option })
  }
 
  render() {
    const defaultOption1 = this.state.selected1
    const placeHolderValue1 = typeof this.state.selected1 === 'string' ? this.state.selected1 : this.state.selected1.value
    

    return [
      <section className="section-div destination-hero">
        <div className="destination-hero-inner">
          <div className="destination-hero-content">
            <h1>{getTranslation(`destinations.hero.title`)}</h1>
            <p>{getTranslation(`destinations.hero.subtitle`)}</p>
            <div className="destBlck">
            <div class="destSelArea">
                <div class="destDropArea">
                  <Dropdown options={options1} onChange={this._onSelect1} value={defaultOption1} placeholder="Select" />
                </div>
                <div className="destBtnArea">
                  <Link to={placeHolderValue1} className="destdownBtn button">{getTranslation(`destinations.hero.cta`)}</Link>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>,
      <div className="destination-parent">
        <section className="section-div section-office">
        <div className="office-area">
          <div className="office-box">
          <h1>{getTranslation(`destinations.highlightDes.title`)}</h1>
            <div className="office-row desk-show">
              <div className="off60 pr8">
              <Link to="/NewYork/">
                <div className="offadd des1">
                  <div className="offAddBlk">
                    <h1>{getTranslation(`destinations.highlightDes.des1.address`)}</h1>
                    <p>{getTranslation(`destinations.highlightDes.des1.addressline1`)}</p>
                    <p>{getTranslation(`destinations.highlightDes.des1.addressline2`)}</p>
                   
                  </div>
                </div>
                </Link>
              </div>
              <div className="off40">
              <Link to="/SanDiego/">
                <div className="offadd des2">
                  <div className="offAddBlk">
                  <h1>{getTranslation(`destinations.highlightDes.des2.address`)}</h1>
                    <p>{getTranslation(`destinations.highlightDes.des2.addressline1`)}</p>
                    <p>{getTranslation(`destinations.highlightDes.des2.addressline2`)}</p>
                   
                  </div>
                </div>
               </Link>
              </div>
              <div className="off40 pr8">
              <Link to="/Perth/">
                <div className="offadd des3">
                  <div className="offAddBlk">
                  <h1>{getTranslation(`destinations.highlightDes.des3.address`)}</h1>
                    <p>{getTranslation(`destinations.highlightDes.des3.addressline1`)}</p>
                    <p>{getTranslation(`destinations.highlightDes.des3.addressline2`)}</p>
                  </div>
                </div>
                </Link>
              </div>
              <div className="off60">
              <Link to="/Barcelona/">
                <div className="offadd des4">
                  <div className="offAddBlk">
                  <h1>{getTranslation(`destinations.highlightDes.des4.address`)}</h1>
                    <p>{getTranslation(`destinations.highlightDes.des4.addressline1`)}</p>
                    <p>{getTranslation(`destinations.highlightDes.des4.addressline2`)}</p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
          {/*accordion*/}
          <div className="office-row mob-show desAcc">
            <Accordion atomic={true}>

              <AccordionItem title={getTranslation(`destinations.highlightDes.des1.address`)}>

                <div className="offAddBlk">
                <Link to="/NewYork/">
                <h1>{getTranslation(`destinations.highlightDes.des1.address`)}</h1>
                    <p>{getTranslation(`destinations.highlightDes.des1.addressline1`)}</p>
                    <p>{getTranslation(`destinations.highlightDes.des1.addressline2`)}</p>
                  <p style={{ height: "50px" }}></p>
                  </Link>
                </div>

              </AccordionItem>

              <AccordionItem title={getTranslation(`destinations.highlightDes.des2.address`)}>
                <div className="offAddBlk">
                <Link to="/SanDiego/">
                <h1>{getTranslation(`destinations.highlightDes.des2.address`)}</h1>
                    <p>{getTranslation(`destinations.highlightDes.des2.addressline1`)}</p>
                    <p>{getTranslation(`destinations.highlightDes.des2.addressline2`)}</p>
                  <p style={{ height: "50px" }}></p>
                  </Link>
                </div>
              </AccordionItem>

              <AccordionItem title={getTranslation(`destinations.highlightDes.des3.address`)}>
                <div className="offAddBlk">
                <Link to="/Perth/">
                <h1>{getTranslation(`destinations.highlightDes.des3.address`)}</h1>
                    <p>{getTranslation(`destinations.highlightDes.des3.addressline1`)}</p>
                    <p>{getTranslation(`destinations.highlightDes.des3.addressline2`)}</p>
                  <p style={{ height: "50px" }}></p>
                  </Link>
                </div>
              </AccordionItem>

              <AccordionItem title={getTranslation(`destinations.highlightDes.des4.address`)}>
                <div className="offAddBlk">
                <Link to="/Barcelona/">
                <h1>{getTranslation(`destinations.highlightDes.des4.address`)}</h1>
                    <p>{getTranslation(`destinations.highlightDes.des4.addressline1`)}</p>
                    <p>{getTranslation(`destinations.highlightDes.des4.addressline2`)}</p>
                  <p style={{ height: "50px" }}></p>
                  </Link>
                </div>
              </AccordionItem>

            </Accordion>

          </div>
          <div className="ofc-btn-area">
            <a href="https://www.ef.com/ils/" className="button offc-btn" target="_blank" rel="noopener noreferrer">{getTranslation(`destinations.highlightDes.cta`)}</a>
          </div>

        </div>
      </section >

        


        

      </div>





    ];
  }
}


