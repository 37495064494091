import React, { Component } from 'react';
import { getTranslation } from './utils';

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="foot-logo">
                    <img src="https://efdigitalmarketing.github.io/templates/assets/logos/EFlogo_vector-black.svg" alt="EF" />
                </div>
                <div className="foot-top">
                    <div className="foot-area">
                        <div className="foot-box">
                            <div className="foot-menu-row">
                                <ul>
                                    <li>
                                        <a href={getTranslation(`footer.link1href`)} target="_blank" rel="noopener noreferrer">{getTranslation(`footer.link1txt`)}</a>
                                    </li>
                                    <li>
                                        <a href={getTranslation(`footer.link2href`)} target="_blank" rel="noopener noreferrer">{getTranslation(`footer.link2txt`)}</a>
                                    </li>
                                    <li>
                                        <a href={getTranslation(`footer.link3href`)} target="_blank" rel="noopener noreferrer">{getTranslation(`footer.link3txt`)}</a>
                                    </li>
                                    <li>
                                        <a href={getTranslation(`footer.link4href`)} target="_blank" rel="noopener noreferrer">{getTranslation(`footer.link4txt`)}</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="foot-copyright">{getTranslation(`footer.copyright`)}</div>
                        </div>
                    </div>
                </div>
                

            </footer>
        );
    }
}


