/* global EF */
import React, { Component } from 'react';
import './pricequote.css';
import { getTranslation } from './utils';
import Script from 'react-inline-script';
import $ from 'jquery';



export default class LongTerm extends Component {
 constructor() {
  super();
  //EF.globals.marketCode = EF.getCurrentMarketCode();
  //EF.globals.marketCode = EF.isLocal() ? 'we' : EF.getCurrentMarketCode();
  //EF.globals.marketCode = 'we';
 }
 componentDidMount() {
  function getQueryVariable(variable){
    var query = window.location.search.substring(1);
    //console.log(query);
    var vars = query.split("&");
    //console.log(vars); 
    for (var i=0;i<vars.length;i++) {
     
                var pair = vars[i].split("=");
                //console.log(pair[1]); 
                EF.globals.marketCode=pair[1];  
                console.log(EF.globals.marketCode);          
    if(pair[0] === variable){return pair[1];}
     }
    
       
}
getQueryVariable('mc');
  window.efps = {
   settings: {
    productCode: "LS",
    productNode: "LY",
    programCode: "LY",
    programNode: "ly",
    siteRoot: "aya",
    marketCode: EF.globals.marketCode,
    ReserveProductCode: "LS",
    ReserveProgramCode: "LY",
    EFComMarketCode:EF.globals.marketCode
   },
   services: {
    buildRelativeUrl: function buildRelativeUrl(relativePath, params) {
     //var mkt = 'WE';
     var querystring = "";
     relativePath = relativePath.indexOf("/") === 0 ? relativePath.substr(1) : relativePath;
     if (params && params.constructor === Array && params[0].name) {
      $.each(params, function (a, b) {
       var str = "&" + b.name + "=" + b.value;
       querystring += str;
      });
      querystring = "?" + querystring.substr(1);
     }
     return "https://www.ef.com/" + relativePath + querystring;
    }
   }
  }
  const script = document.createElement("script");
  script.src = "https://www.ef.com/hub/agentsportal-files/js/reserve-online.js";
  script.async = true;
document.body.appendChild(script);
  
 }
 render() {

  return [
   <section class="section-div pq-hero">
    <div class="pq-hero-inner">
     <div class="pq-hero-content">
      <h1>Long Term Course</h1>
      <p>{getTranslation(`PriceQuote.subtitle`)}</p>
     </div>
    </div>
   </section>,
   <div class="pq-parent">
    <section class="section-div section-pq">

     <div class="wrapper">
      <section class="reserve-online-wrap" id="reserve-online-wrap">
       <div class="shell" id="reserve-online-form-holder">
        <div class="reserve-online-left">
         <div class="reserve-online-step1-wrap js-tracking-specific">
          <div class="reserve-online-content">
           <h2>{getTranslation(`PriceQuote.Term.Course`)}</h2>
           <ul class="preferences-reserve">
            <li>
             <label>{getTranslation(`PriceQuote.Term.Destination`)}</label>
             <select class="select select-options fs-dropdown-element" id="reserve-destinations" name="reserve-destinations">
              <option value="">{getTranslation(`PriceQuote.Term.Destination`)}</option>
              <option class="country" disabled="disabled" value="GB">--- Great Britain ---</option>
              <option data-selected="testing" value="GB|BOU">Bournemouth</option>
              <option data-selected="testing" value="GB|BRI">Brighton</option>
              <option data-selected="testing" value="GB|BST">Bristol</option>
              <option data-selected="testing" value="GB|CAM">Cambridge</option>
              <option data-selected="testing" value="GB|EAS">Eastbourne</option>
              <option data-selected="testing" value="GB|LON">London</option>
              <option data-selected="testing" value="GB|MAN">Manchester</option>
              <option data-selected="testing" value="GB|OXF">Oxford</option>
              <option class="country" disabled="disabled" value="US">--- USA ---</option>
              <option data-selected="testing" value="US|BOI">Boston</option>
              <option data-selected="testing" value="US|CHI">Chicago</option>
              <option data-selected="testing" value="US|HNL">Honolulu</option>
              <option data-selected="testing" value="US|LAR">Los Angeles</option>
              <option data-selected="testing" value="US|MIA">Miami Beach</option>
              <option data-selected="testing" value="US|NYC">New York</option>
              <option data-selected="testing" value="US|SDC">San Diego</option>
              <option data-selected="testing" value="US|SFD">San Francisco</option>
              <option data-selected="testing" value="US|SBA">Santa Barbara</option>
              <option data-selected="testing" value="US|SEA">Seattle</option>
              <option data-selected="testing" value="US|WAS">Washington D.C.</option>
              <option class="country" disabled="disabled" value="IE">--- Ireland ---</option>
              <option data-selected="testing" value="IE|DUB">Dublin</option>
              <option class="country" disabled="disabled" value="CA">--- Canada ---</option>
              <option data-selected="testing" value="CA|VAN">Vancouver</option>
              <option data-selected="testing" value="CA|VIC">Vancouver Island</option>
              <option data-selected="testing" value="CA|TOO">Toronto</option>
              <option class="country" disabled="disabled" value="MT">--- Malta ---</option>
              <option data-selected="testing" value="MT|MSJ">St. Julians</option>
              <option class="country" disabled="disabled" value="SA">--- South Africa ---</option>
              <option data-selected="testing" value="SA|CAP">Cape Town</option>
              <option class="country" disabled="disabled" value="AU">--- Australia ---</option>
              <option data-selected="testing" value="AU|BRS">Brisbane</option>
              <option data-selected="testing" value="AU|PER">Perth</option>
              <option data-selected="testing" value="AU|SYD">Sydney</option>
              <option class="country" disabled="disabled" value="NZ">--- New Zealand ---</option>
              <option data-selected="testing" value="NZ|AUC">Auckland</option>
              <option class="country" disabled="disabled" value="FR">--- France ---</option>
              <option data-selected="testing" value="FR|NIC">Nice</option>
              <option data-selected="testing" value="FR|PAR">Paris</option>
              <option class="country" disabled="disabled" value="DE">--- Germany ---</option>
              <option data-selected="testing" value="DE|BER">Berlin</option>
              <option data-selected="testing" value="DE|MUN">Munich</option>
              <option class="country" disabled="disabled" value="IT">--- Italy ---</option>
              <option data-selected="testing" value="IT|ROM">Rome</option>
              <option class="country" disabled="disabled" value="PT">--- Portugal ---</option>
              <option data-selected="testing" value="PT|LIS">Lisbon</option>
              <option class="country" disabled="disabled" value="CR">--- Costa Rica ---</option>
              <option data-selected="testing" value="CR|COS">Playa Tamarindo</option>
              <option class="country" disabled="disabled" value="CN">--- China ---</option>
              <option data-selected="testing" value="CN|SHA">Shanghai</option>
              <option class="country" disabled="disabled" value="KR">--- Korea ---</option>
              <option data-selected="testing" value="KR|SEL">Seoul</option>
              <option class="country" disabled="disabled" value="SG">--- Singapore ---</option>
              <option data-selected="testing" value="SG|SIN">Singapore</option>
              <option class="country" disabled="disabled" value="AE">--- United Arab Emirates ---</option>
              <option data-selected="testing" value="AE|DXB">Dubai</option>
              <option class="country" disabled="disabled" value="JP">--- Japan ---</option>
              <option data-selected="testing" value="JP|TYO">Tokyo</option>
              <option class="country" disabled="disabled" value="ES">--- Spain ---</option>
              <option data-selected="testing" value="ES|MAA">Malaga</option>
              <option data-selected="testing" value="ES|BAR">Barcelona</option>
              <option data-selected="testing" value="ES|MAD">Madrid</option>
             </select>
            </li>
            <li class="disabled">
             <label>{getTranslation(`PriceQuote.Term.Coursetype`)}</label>
             <select class="select select-options fs-dropdown-element" id="reserve-courses">
              <option value="">{getTranslation(`PriceQuote.Term.Coursetype`)}</option>
              <option value="I|0|0" data-description="INCREASE THE INTENSITY FOR FASTER PROGRESS">Intensive Course</option>
              <option value="G|0|0" data-description="A well-rounded program of language study">General Course</option>
              <option value="E|0|0" data-description="Prepare for and take an internationally recognized language exam.">Exam Preparation Programme</option>
              <option value="MHT|0|0" data-description="Learn from the best and boost your knowledge of the hotel and tourism industry while perfecting your new language, with case studies straight from top hotel chains.">EF Advanded Diploma for Hotel &amp; Tourism</option>
             </select>
            </li>
            <li class="disabled">
             <div class="error-text-message">{getTranslation(`PriceQuote.Term.Error`)}</div>
             <label>{getTranslation(`PriceQuote.Term.Startdate`)}</label>
             <select class="select select-options fs-dropdown-element" id="reserve-start-date" tabindex="-1">
              <option value="">{getTranslation(`PriceQuote.Term.Startdate`)}</option>
             </select>
             <div class="form-content">
              <h4></h4>
             </div>
            </li>
            <li class="disabled">
             <label>{getTranslation(`PriceQuote.Term.CourseLength`)}</label>
             <select class="select select-options fs-dropdown-element" id="reserve-course-length" tabindex="-1">
              <option value="">{getTranslation(`PriceQuote.Term.CourseLength`)}</option>
             </select>
            </li>
           </ul>
          </div>
          <div class="reserve-online-content reserve-online-accomdation">
           <h2>{getTranslation(`PriceQuote.Term.Accommodation`)}</h2>
           <p>{getTranslation(`PriceQuote.Term.accDesc`)}</p>
           <h4>{getTranslation(`PriceQuote.Term.Homestay`)}</h4>
           <div class="form-radiogroup" id="reserve-accom-homestay">
           </div>
           <h4>{getTranslation(`PriceQuote.Term.residence`)}</h4>
           <div class="form-radiogroup" id="reserve-accom-residence">
           </div>
          </div>
          <div class="reserve-online-content reserve-online-insurance">
           <h2>{getTranslation(`PriceQuote.Term.insurance`)}</h2>
           <div id="reserve-online-insurance">
           </div>
          </div>
          <div id="reserve-online-extradata">
          </div>
         </div>
        </div>
        <div class="reserve-online-right">
         <div class="booking-summary-wrap" id="booking-summary-wrap">
          <h2>{getTranslation(`PriceQuote.Term.BookingSummary`)}</h2>
          <div class="booking-summary-container">
           <div class="booking-summary course-summary">
            <h3>{getTranslation(`PriceQuote.Term.Course`)}</h3>
            <div id="course-detail-summary">
            </div>
            <div class="course-summary-price" id="course-price"></div>
           </div>
           <div class="booking-summary accomodation-summary">
            <h3>{getTranslation(`PriceQuote.Term.Accommodation`)}</h3>
            <div class="course-summary-price" id="accomodation-price"></div>
           </div>
           <div class="booking-summary insurance-summary">
            <h3>{getTranslation(`PriceQuote.Term.insurance`)}</h3>
            <div class="course-summary-price" id="insurance-price-text"></div>
            <div class="course-summary-price" id="insurance-price-summary"></div>
           </div>
           <div class="booking-summary extra-summary">
            <h3></h3>
            <div class="course-summary-price" id="extra-price-text">
             <p id="appFee" data-articlecode="appFee"> <span>{getTranslation(`PriceQuote.Term.fee1`)}</span> <span></span></p>
             <p id="bookPrice" data-articlecode="bookPrice"> <span>{getTranslation(`PriceQuote.Term.fee2`)}</span> <span></span></p>
             <p id="wssPrice" data-articlecode="wss"> <span>{getTranslation(`PriceQuote.Term.fee3`)}</span> <span></span></p>
             <p id="wmssPrice" data-articlecode="wmss"> <span>{getTranslation(`PriceQuote.Term.fee4`)}</span> <span></span></p>
            </div>
            <div class="course-summary-price" id="extra-price-summary"></div>
           </div>
           <div class="booking-summary totalprice-summary desktop-only">
            <h3>{getTranslation(`PriceQuote.Term.total`)}<span></span></h3>
           </div>
           <p class="residence-disclaimer">{getTranslation(`PriceQuote.Term.extra`)}</p>
          </div>
         </div>
        </div>
       </div>
      </section>
     </div>
     <Script type="text/jsx" id="reserve-course-template">
      {`<option value="{{Coursenumber}}|{{MinLength}}|{{MaxLength}}" data-description="{{CourseDescription}}">{{Coursename}}</option>`}
     </Script>
     <Script type="text/jsx" id="reserve-start-date-template">
      {`<option value="{{StartDate}}" data-enddate="{{EndDate}}">{{DisplayDate}}</option>`}
     </Script>
     <Script type="text/jsx" id="reserve-course-length-template">
      {`<option data-selected=" " value="{{Parameters}}" data-price="{{Price}}">{{Week}}</option>`}
     </Script>
     <Script type="text/jsx" id="reserve-homestay-template">
     {` <div class="form-radiosingle">
       <div class="form-radio-left">
        <input id="{{Accomcode}}" name="accomodation" class="homestay" type="radio" value="{{Translation}}" data-price="{{Price}}" data-pricetpe="{{PriceType}}" data-prechecked="{{IsPrechecked}}">
         <label for="{{Accomcode}}">{{Translation}}</label>
         <div class="form-content">
          <p>{{MoreInformation}}</p>
         </div>
          </div>
        <p>{{Price}} {{CurrencyCode}}</p>
        <div class="form-content-mobile">
         <p>{{MoreInformation}}</p>
        </div>
       </div>`}
      </Script>
      <Script type="text/jsx" id="reserve-residence-template">
       {`<div>
        <div class="form-radiosingle">
         <div class="form-radio-left">
          <input id="{{Accomcode}}" name="accomodation" class="residence" type="radio" value="{{Translation}}" data-price="{{Price}}" data-pricetpe="{{PriceType}}" >
           <label for="{{Accomcode}}">{{Translation}}</label>
            </div>
          <p><i>from</i><br /> {{Price}} {{CurrencyCode}}</p>
         </div>
         <p>{{MoreInformation}}</p>
        </div>`}
      </Script>
       <Script type="text/jsx" id="reserve-insurance-template">
        {`<div class="form-checkgroup">
         <div class="form-checkgroup-left">
          <input id="{{Articlecode}}" name="cancelinsurance" type="checkbox" value="{{Translation}}" data-price="{{Price}}" data-pricetpe="{{PriceType}}" data-prechecked="{{IsPrechecked}}" data-compulsory="{{IsCompulsory}}">
           <label for="{{Articlecode}}" data-compulsory="{{IsCompulsory}}">{{Translation}} <span class="mandatory-text">(Required)</span></label>
           <div class="form-content">
            <p>{{MoreInformation}}</p>
           </div>
          </div>
          <p>{{Price}} {{CurrencyCode}}</p>
          <div class="form-content-mobile">
           <p>{{MoreInformation}}</p>
          </div>
         </div>`}
      </Script>
        <Script type="text/jsx" id="reserve-extradata-template">
         {`<p data-price="{{Price}}" data-articlecode="{{ArticleCode}}" data-value="{{Parameters}}" data-price="{{Price}}" data-pricetpe="{{PriceType}}" data-week="{{Week}}">{{Price}}</p>`}
        </Script>



    </section>


   </div>





  ];
 }
}


