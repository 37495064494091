import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import { getTranslation } from './utils';
const options1 = [
 {value: 'https://www.ef.com/ils/courses/ef-language-courses/basic-course/', label: 'Basic course' },
 {value: 'https://www.ef.com/ils/courses/ef-language-courses/general-course/', label: 'General Course' },
 {value: 'https://www.ef.com/ils/courses/ef-language-courses/intensive-course/', label: 'Intensive course' },
 {value: 'https://www.ef.com/ils/courses/exam-preparation-courses/', label: 'Exam preparation ' },
 {value: 'https://www.ef.com/ils/courses/career-builder-courses/english-for-business/', label: 'English for Business' },
 {value: 'https://www.ef.com/ils/courses/career-builder-courses/EF-Career-Plus/', label: 'EF Career Plus' },
 {value: 'https://www.ef.com/ils/courses/ef-junior-courses/junior-course/', label: 'Junior Course' },
 {value: 'https://www.ef.com/ils/courses/ef-junior-courses/junior-intensive-course/', label: 'Junior Intensive Course' },
]
const options2 = [
 {value: 'https://www.ef.com/aya/programmes/language-programmes/intensive/', label: 'Intensive Language Year' },
 {value: 'https://www.ef.com/aya/programmes/language-programmes/general/', label: 'General Language Year' },
  
]
const options3 = [
 {value: 'https://www.ef.com/upa/programmes/undergraduate/ufy-uk-singapore/', label: 'University Foundation Year in the UK & Singapore' },
 {value: 'https://www.ef.com/upa/programmes/undergraduate/ufy-usa-north-america/', label: "University Foundation Year in North America" },
 {value: 'https://www.ef.com/upa/programmes/undergraduate/ufy-australia/', label: 'University Foundation Year in Australia' },
 {value: 'https://www.ef.com/upa/programmes/undergraduate/ufy-france/', label: 'University Foundation Year in France' },
 {value: 'https://www.ef.com/upa/programmes/undergraduate/ufy-germany/', label: 'University Foundation Year in Germany'},
 {value: 'https://www.ef.com/upa/programmes/graduate/pre-masters/', label: 'PreMaster'},
 {value: 'https://www.ef.com/upa/programmes/graduate/pre-mba/', label: 'PreMBA'},
 {value: 'https://www.ef.com/upa/programmes/doctoral/pre-phd-uk/', label: 'PrePHD Program'},
 {value: 'https://www.ef.com/upa/programmes/doctoral/pre-phd-germany/', label: 'PrePHD Program in Germany'},
 {value: 'https://www.ef.com/upa/programmes/undergraduate/accelerated-college-degree/', label: 'Accelerated College Degree Program'},
]

export default class Courses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected1: '',
      selected2: '',
      selected3: ''
    }
    this._onSelect1 = this._onSelect1.bind(this)
    this._onSelect2 = this._onSelect2.bind(this)
    this._onSelect3 = this._onSelect3.bind(this)
  }

  _onSelect1(option) {
    this.setState({ selected1: option })
  }
  _onSelect2(option) {
    this.setState({ selected2: option })
  }
  _onSelect3(option) {
    this.setState({ selected3: option })
  }
  render() {
    const defaultOption1 = this.state.selected1
    const placeHolderValue1 = typeof this.state.selected1 === 'string' ? this.state.selected1 : this.state.selected1.value
    const defaultOption2 = this.state.selected2
    const placeHolderValue2 = typeof this.state.selected2 === 'string' ? this.state.selected2 : this.state.selected2.value
    const defaultOption3 = this.state.selected3
    const placeHolderValue3 = typeof this.state.selected3 === 'string' ? this.state.selected3 : this.state.selected3.value
    return [
      <section class="section-div courses-hero">
        <div class="courses-hero-inner">
          <div class="courses-hero-content">
            <h1>{getTranslation(`Courses.hero.title`)}</h1>
            <p>{getTranslation(`Courses.hero.subtitle`)}</p>
          </div>
        </div>
      </section>,
      <section class=" section-div section-courses borderCourse">
        <div class="courses-area">
          <div class="courses-box">
            <h1>{getTranslation(`Courses.ShortTerm.title`)}</h1>
            <p>{getTranslation(`Courses.ShortTerm.description`)}</p>

            <div class="coursesdropArea">
              <Dropdown options={options1} onChange={this._onSelect1} value={defaultOption1} placeholder="Select" />
            </div>
            <div class="coursesbtnArea">
              <a href={placeHolderValue1} class="coursesdownBtn button" target="_blank" rel="noopener noreferrer">{getTranslation(`Courses.ShortTerm.cta`)}</a>
            </div>

          </div>
        </div>
      </section>,
      <section class=" section-div section-courses borderCourse">
        <div class="courses-area">
          <div class="courses-box">
            <h1>{getTranslation(`Courses.LongTerm.title`)}</h1>
            <p>{getTranslation(`Courses.LongTerm.description`)}</p>

            <div class="course-half">
              <h1 class="coursesdropHead">{getTranslation(`Courses.LongTerm.drop1Title`)}</h1>
              <div class="coursesdropArea">
                <Dropdown options={options2} onChange={this._onSelect2} value={defaultOption2} placeholder="Select" />
              </div>
              <div class="coursesbtnArea">
                <a href={placeHolderValue2} class="coursesdownBtn button" target="_blank" rel="noopener noreferrer">{getTranslation(`Courses.LongTerm.cta`)}</a>
              </div>
            </div>
            <div class="course-half">
              <h1 class="coursesdropHead">{getTranslation(`Courses.LongTerm.drop2Titile`)}</h1>
              <div class="coursesdropArea">

                <Dropdown options={options3} onChange={this._onSelect3} value={defaultOption3} placeholder="Select" />
              </div>
              <div class="coursesbtnArea">
                <a href={placeHolderValue3} class="coursesdownBtn button" target="_blank" rel="noopener noreferrer">{getTranslation(`Courses.LongTerm.cta`)}</a>
              </div>
            </div>

          </div>
        </div>
      </section>,






    ];
  }
}


