import firebase from 'firebase';
const config = {
    apiKey: "AIzaSyAzL_4POD4WP3HdoDdibeJFj88L2ACAub0",
    authDomain: "agentsportalauth.firebaseapp.com",
    databaseURL: "https://agentsportalauth.firebaseio.com",
    projectId: "agentsportalauth",
    storageBucket: "",
    messagingSenderId: "583134974860"
};
const fire = firebase.initializeApp(config);
export default fire;