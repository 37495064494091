import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import { Accordion, AccordionItem } from 'react-light-accordion';
import { getTranslation } from './utils';

const options1 = [
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/visa/ESTA_Information.pdf', label: 'General Information' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/visa/Sample_I-20.pdf', label: 'Sample I-20' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/visa/Letter_of_support_for_16-17_years_old.doc', label: 'Letter of support' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/visa/CAS_request_checklist.pdf', label: 'CAS request checklist' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/visa/IHS_Surchage_Information.pdf', label: 'IHS Surchage FAQ' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/visa/BRP_Information.pdf', label: 'BRP FAQ' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/visa/Tier_4_Information.pdf', label: 'Tier 4 information' },
]
const options2 = [
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/ILS_Enrolment_form.pdf', label: 'ILS Enrolment Form' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/AY_Enrolment_Form.pdf', label: 'AY Enrolment Form' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/UP_Enrollment_form.pdf', label: 'UP Enrolment Form' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Junior_Enrollment_form.pdf', label: 'Juniors Enrolment Form' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Worldwide_Junior_Code_of_Conduct_and_Medical_Release_form.pdf', label: 'Code of conduct' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Guaranteed_Placement_Waiver_Letter-UFY.doc', label: 'Liability waiver' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Liabilty_Waiver_Saint_Raphael.pdf', label: 'Waiver for Saint Raphael' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Agent_Commission_Payout_Form.xlsx', label: 'Commission payout form' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Credit_Card_Refund_Form.XLS', label: 'Refund form' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Payment_Auth_Form.xls', label: 'Payment Auth Form' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Pre-PHD_Research_Proposal_Template.docx', label: 'Pre-PHD Research' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Statement_of_Purpose_and_Disclaimer.doc', label: 'UP Statement of purpose' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Statement_of_Purpose_and_Disclaimer.doc', label: 'UP Disclaimer' },
  { value: 'https://efdigitalmarketing.github.io/templates/Agent-portal/forms/Worldwide_guidance_sheet.xls', label: 'UP Worldwide guidance sheet' },
]


export default class Materials extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected1: '',
      selected2: ''
    }
    this._onSelect1 = this._onSelect1.bind(this)
    this._onSelect2 = this._onSelect2.bind(this)
  }

  _onSelect1(option) {
    this.setState({ selected1: option })
  }
  _onSelect2(option) {
    this.setState({ selected2: option })
  }

  render() {
    const defaultOption1 = this.state.selected1
    const placeHolderValue1 = typeof this.state.selected1 === 'string' ? this.state.selected1 : this.state.selected1.value
    const defaultOption2 = this.state.selected2
    const placeHolderValue2 = typeof this.state.selected2 === 'string' ? this.state.selected2 : this.state.selected2.value
    return [
      <section class="section-div material-hero">
        <div class="material-hero-inner">
          <div class="material-hero-content">
        <h1>{getTranslation(`Materials.hero.MaterialTitle`)}</h1>
        <p>{getTranslation(`Materials.hero.materialSubtitle`)}</p>
          </div>
        </div>
      </section>,
      <section class="section-div materials-section">
        <div class="materials-inner">
          <div class="materials-con">
         <h1>{getTranslation(`Materials.Video.video1Head`)}</h1>
         <p>{getTranslation(`Materials.Video.video1Subtitle`)}</p>
            <div class="material-videos">

              <div class="material-videos-row">
                <ul>
                  <li>
             <a href={getTranslation(`Materials.Video.video1efURL`)} target="_blank" rel="noopener noreferrer">
                      <div class="material-videos-block materialVideo1">
                        <div class="materialVideoCon">
                <h1>{getTranslation(`Materials.Video.video1efText`)}</h1>

                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
             <a href={getTranslation(`Materials.Video.video1languageUrl`)} target="_blank" rel="noopener noreferrer">
                      <div class="material-videos-block materialVideo2">
                        <div class="materialVideoCon">
                <h1>{getTranslation(`Materials.Video.video1languageText`)}</h1>

                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
             <a href={getTranslation(`Materials.Video.video1guaranteUrl`)} target="_blank" rel="noopener noreferrer">
                      <div class="material-videos-block materialVideo3">
                        <div class="materialVideoCon">
                <h1>{getTranslation(`Materials.Video.video1guaranteText`)}</h1>

                        </div>
                      </div>
                    </a>
                  </li>

                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>,
      <section class="section-div materials-section">
        <div class="materials-inner">
          <div class="materials-con">
         <h1>{getTranslation(`Materials.Video.video2Head`)}</h1>
         <p>{getTranslation(`Materials.Video.video2Subtitle`)}</p>
            <div class="material-videos">

              <div class="material-videos-row">
                <ul>
                  <li>
             <a href={getTranslation(`Materials.Video.video2hostUrl`)} target="_blank" rel="noopener noreferrer">
                      <div class="material-videos-block materialVideo4">
                        <div class="materialVideoCon">
                <h1>{getTranslation(`Materials.Video.video2hostText`)}</h1>

                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
             <a href={getTranslation(`Materials.Video.video2infoUrl`)} target="_blank" rel="noopener noreferrer">
                      <div class="material-videos-block materialVideo5">
                        <div class="materialVideoCon">
                <h1>{getTranslation(`Materials.Video.video2infoText`)}</h1>

                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
             <a href={getTranslation(`Materials.Video.video2preUrl`)} target="_blank" rel="noopener noreferrer">
                      <div class="material-videos-block materialVideo6">
                        <div class="materialVideoCon">
                <h1>{getTranslation(`Materials.Video.video2preText`)}</h1>

                        </div>
                      </div>
                    </a>
                  </li>

                </ul>
              </div>



            </div>
          </div>
        </div>
      </section>,
      <section class=" section-div section-materials materialsTop">
        <div class="materials-area">
          <div class="materials-box">
            <h1>{getTranslation(`Materials.informations.inf1Title`)}</h1>
            <div class="dropArea">
              <Dropdown options={options1} onChange={this._onSelect1} value={defaultOption1} placeholder="Select" />
            </div>
            <div class="btnArea">
              <a href={placeHolderValue1} class="downBtn button" target="_blank" rel="noopener noreferrer">{getTranslation(`Materials.informations.infcta`)}</a>
            </div>

          </div>
        </div>
      </section>,
      <section class=" section-div section-materials">
        <div class="materials-area">
          <div class="materials-box">
            <h1>{getTranslation(`Materials.informations.inf2Title`)}</h1>
            <div class="dropArea">
              <Dropdown options={options2} onChange={this._onSelect2} value={defaultOption2} placeholder="Select" />
            </div>
            <div class="btnArea">
              <a href={placeHolderValue2} class="downBtn button" target="_blank" rel="noopener noreferrer">{getTranslation(`Materials.informations.infcta`)}</a>
            </div>

          </div>
        </div>
      </section>,
      <section class="section-div section-faq faq-pb">
        <div class="faq-area">
          <div class="faq-box">
            <h1>{getTranslation(`Materials.faq.faqTitile`)}</h1>
            <div class="tab-area">

              <Accordion atomic={true}>

                <AccordionItem title={getTranslation(`Materials.faq.q1`)}>

                  <p class="accPara">
                    <ul>
                      <li>{getTranslation(`Materials.faq.a1l1`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l2`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l3`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l4`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l5`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l6`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l7`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l8`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l9`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l10`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l11`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l12`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l13`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l14`)}</li>
                      <li>{getTranslation(`Materials.faq.a1l15`)}</li>
                    </ul>
                  </p>

                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q2`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a2`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q3`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a3`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q4`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a4`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q5`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a5`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q6`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a6`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q7`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a7`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q8`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a8`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q9`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a9`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q10`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a10`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q11`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a11`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q12`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a12`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q13`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a13`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q14`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a14`)} <a href="https://efdigitalmarketing.github.io/templates/Agent-portal/visa/Tier_4_Information.pdf" target="_blank" rel="noopener noreferrer">Tier_4_Information</a></p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q15`)}>
                  <p class="accPara">
                    <ul>
                      <li>{getTranslation(`Materials.faq.a15l1`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l2`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l3`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l4`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l5`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l6`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l7`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l8`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l9`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l10`)}</li>
                      <li>{getTranslation(`Materials.faq.a15l11`)}</li>

                    </ul>
                  </p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q16`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a16`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q17`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a17`)} <a href="https://www.erikainsurance.com/" target="_blank" rel="noopener noreferrer">Erika</a></p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q18`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a18`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q19`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a19`)}</p>
                </AccordionItem>

                <AccordionItem title={getTranslation(`Materials.faq.q20`)}>
                  <p class="accPara">{getTranslation(`Materials.faq.a20`)}</p>
                </AccordionItem>

              </Accordion>
            </div>
          </div>
        </div>
      </section>

    ];
  }
}


