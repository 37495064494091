import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import { getTranslation } from './utils';


const options1 = [
  { value: '/ShortTerm/', label: 'Short term courses' },
  { value: '/LongTerm/', label: 'Long term courses' },

]
const options2 = [
  { value: 'mc=we', label: 'English' },
  { value: 'mc=fr', label: 'Français' },
  { value: 'mc=sa', label: 'العربية' },
  { value: 'mc=kr', label: '한국어' },
  { value: 'mc=ru', label: 'Русский' },
  { value: 'mc=es', label: 'Español' },
  { value: 'mc=cn', label: '中文' },
]



export default class PriceQuotations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected1: '',
      selected2: '',

    }
    this._onSelect1 = this._onSelect1.bind(this)
    this._onSelect2 = this._onSelect2.bind(this)
  }

  _onSelect1(option) {
    this.setState({ selected1: option })
  }
  _onSelect2(option) {
    this.setState({ selected2: option })
  }




  render() {
    const defaultOption1 = this.state.selected1
    const placeHolderValue1 = typeof this.state.selected1 === 'string' ? this.state.selected1 : this.state.selected1.value
    const defaultOption2 = this.state.selected2
    const placeHolderValue2 = typeof this.state.selected2 === 'string' ? this.state.selected2 : this.state.selected2.value


    return [
      <section className="section-div pq-hero">
        <div className="pq-hero-inner">
          <div className="pq-hero-content">
            <h1>{getTranslation(`PriceQuote.title`)}</h1>
            <p>{getTranslation(`PriceQuote.subtitle`)}</p>
            <div className="pqBlck">
            <div className="pqSelArea">
                <div className="pqDropArea">
                  <Dropdown options={options1} onChange={this._onSelect1} value={defaultOption1} placeholder="Select" />
                  <Dropdown options={options2} onChange={this._onSelect2} value={defaultOption2} placeholder="Select" />
                </div>
                <div className="pqBtnArea">
                  <Link to={placeHolderValue1+"?"+placeHolderValue2} class="pqdownBtn button">{getTranslation(`PriceQuote.ctatxt`)}</Link>

                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>





    ];
  }
}


